// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/login`,
      verify: `${ROOTS.AUTH}/verify`,
      register: `${ROOTS.AUTH}/register`,
      newPassword: `${ROOTS.AUTH}/new-password`,
      firstAccess: `${ROOTS.AUTH}/first-access`,
      totpCode: `${ROOTS.AUTH}/totp-code`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/`,
    employees: `${ROOTS.DASHBOARD}/employees`,
    newEmployee: `${ROOTS.DASHBOARD}/employees/new`,
    timesheet: `${ROOTS.DASHBOARD}/timesheet`,
    requests: `${ROOTS.DASHBOARD}/requests`,
    calendar: `${ROOTS.DASHBOARD}/timetable`,
    documents: `${ROOTS.DASHBOARD}/documents`,
    letters: `${ROOTS.DASHBOARD}/letters`,
    clockings: `${ROOTS.DASHBOARD}/clockings`,
    notices: `${ROOTS.DASHBOARD}/comunicazioni`,
    expenseReports: `${ROOTS.DASHBOARD}/note-spese`,
    helpDesk: `${ROOTS.DASHBOARD}/help-desk`,
    stats: `${ROOTS.DASHBOARD}/stats`,
    company: `${ROOTS.DASHBOARD}/company`,
    account: `${ROOTS.DASHBOARD}/account`,
    news: {
      root: `${ROOTS.DASHBOARD}/news`,
      details: (id) => `${ROOTS.DASHBOARD}/news/${id}`,
    },
    faq: `${ROOTS.DASHBOARD}/faq`,
    config: {
      root: `${ROOTS.DASHBOARD}/config`,
      timesheet: `${ROOTS.DASHBOARD}/config/timesheet`,
      expenseReports: `${ROOTS.DASHBOARD}/config/expense-reports`,
      clockingPlaces: `${ROOTS.DASHBOARD}/config/clocking-places`,
      supervisors: `${ROOTS.DASHBOARD}/config/supervisors`,
      orgChart: `${ROOTS.DASHBOARD}/config/org-chart`,
    },
    payrollData: `${ROOTS.DASHBOARD}/payroll-data`,
  },
};
