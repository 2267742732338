import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/auth/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordIcon from "src/assets/icons/password-icon";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import { paths } from "src/routes/paths";
import { RouterLink } from "src/routes/components";
import { useRouter, useSearchParams } from "src/routes/hooks";

import Iconify from "src/components/iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";

// ----------------------------------------------------------------------

export default function AmplifyTOTPCodeView() {
  const router = useRouter();

  const searchParams = useSearchParams();

  const email = searchParams.get("email");

  const { confirmLogin } = useAuthContext();

  const VerifySchemaSchema = Yup.object().shape({
    code: Yup.string()
      .min(6, "Il codice deve essere di almeno 6 caratteri")
      .required("Codice obbligatorio"),
  });

  const defaultValues = {
    code: "",
    email: email || "",
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(VerifySchemaSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await confirmLogin?.(data.code);
      router.push(paths.dashboard.root);
    } catch (error) {
      console.error(error);
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="code"
        label="Codice"
        InputLabelProps={{ shrink: true }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Accedi
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.auth.amplify.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Torna alla login
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ mt: 3, mb: 5 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Per la tua sicurezza, conferma il tuo accesso inserendo il codice di
          verifica generato dalla tua app di autenticazione
        </Typography>
      </Stack>
    </>
  );

  return (
    <>
      {renderHead}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
    </>
  );
}
